import { trigger, state, style, transition, animate } from '@angular/animations';

export const expandCollapse = trigger('expandCollapse', [
  state(
    'collapsed',
    style({
      height: '100px',
      overflow: 'hidden',
      opacity: 0.5,
    })
  ),
  state(
    'expanded',
    style({
      height: '*',
      overflow: 'visible',
      opacity: 1,
    })
  ),
  transition('collapsed => expanded', [
    animate('0.5s ease-out'),
  ]),
  transition('expanded => collapsed', [
    style({ overflow: 'hidden' }),
    animate('0.5s ease-in'),
  ]),
]);
