// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    backendUrl:'https://lozzcms.isolvedev.website',
    conomeUrl: "https://lozz.conome.mk",
    API_TOKEN: '99135cd947cb07a9ec4450386380fe29a8fbd3cc9cc2eff91128a7a28807b8641e6c6058015d6065a751f5c3b632579b5fc795566ba8029631e4d46eecc01e59c8a2d81dda17a791e0f70a31d76079bdc85001250dec1aa7daa3bd33495b98cbb75dd211c340b40e5231b5d6640bed7376af10fcf535b4bc727200035beb6bad',
    company_token: "JjaaenKox96zq7Pu3wIQu0cf7DO3hPjE"
    };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
