import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, AfterViewInit, PLATFORM_ID, Inject, TransferState, makeStateKey } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE } from '../../modules/constants';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { SeoService } from 'src/app/services/seoService/seo.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { Observable, of } from 'rxjs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from '../../services/user/user-profile.service';
Swiper.use([Navigation, Pagination]);

@Component({
    selector: 'app-wishlist',
    // standalone: true,
    // imports: [AppModule],
    templateUrl: './wishlist.component.html',
    styleUrl: './wishlist.component.scss'
})
export class WishlistComponent {

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private modalService: NgbModal,
        private cartService: CartService,
        private seo: SeoService,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private conomeService: ConomeECommerceService,
        private transferState: TransferState,
        private cmsService: LozztoysCmsService,
        private userProfileService: UserProfileService
    ) {
        this.environment = environment;
    }
    productsFromConome: any[] = [];
    categoriesFromConome: any[] = [];
    sortedProducts: any[] = [];
    conomeUrl = environment.conomeUrl
    companyId: number = 0;
    productsFromWishList: any
    currentLanguage = this.translateService.currentLang;
    environment: any;
    isProductDetailModal: boolean = false
    currentProduct: any;
    currentProductImages: any;
    currentProductDetail: any;
    successMessage: string = '';
    showSuccessMessage: boolean = false
    showWarningMessage: boolean = false
    userId: string = '';
    wishListCmsData: any;
    headersFromCms: any;
    productsJustForYou: any;
    conomeProductsJustForYou: any[] = [];

    ngOnInit() {
        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });
        this.companyId = Number(this.cookieService.get('companyID'));
        this.getWishListData();
        this.getHeaders();

        this.translateService.onLangChange.subscribe((res) => {
            if (this.currentLanguage !== res.lang) {
                this.currentLanguage = res.lang;
                this.getHeaders();
            }
        });
    }


    getFromWishList(): Observable<any[]> {
        return this.cmsService.getWishList(this.userId).pipe(
            map((res: any) => {
                return res.data
                    .map((item: any) => ({
                        productId: item.attributes.productId,
                        productDetailId: item.attributes.productDetailId,
                        userId: item.attributes.userId
                    }));
            })
        );
    }

    getHeaders() {
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getwishlistHeaders(populateAndLocale).subscribe({
            next: (res) => {
                this.headersFromCms = res.data.attributes;
            }
        })
    }

    getWishListData() {
        if (this.userId === '') {
            const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            this.wishListCmsData = wishListCmsData ? JSON.parse(wishListCmsData) : [];
            if (this.wishListCmsData.length > 0) {
                const wishListIds = this.wishListCmsData.map((data: any) => data.productId);
                this.getProductById(wishListIds);
            }
        } else {
            this.getFromWishList().subscribe({
                next: (wishlistCmsData: any[]) => {
                    this.wishListCmsData = wishlistCmsData;

                    const wishListIds = this.wishListCmsData.map((data: any) => data.productId);
                    this.getProductById(wishListIds);
                }
            });
        }
    }

    getProductById(wishListIds: number[]){
        this.conomeService.getProductsById(this.companyId, wishListIds).subscribe((data: any) =>{
            this.conomeService.setproductsFromWishlist(data.products)
            this.productsFromWishList = data.products;
                this.fetchConomeData();
        })
    }

    private processCombinedProducts(products: any[]): Observable<any> {
        const processedProducts = products.map(product => {
            return {
                ...product,
                processed: true
            };
        });
    
        return of(processedProducts);
    }

    getCartData(productIds: number[]): void {
        if (productIds.length === 0) {
            return;
        }
        this.conomeService.productsFromWishlist$
            .pipe(
                switchMap(productsFromWishlist => {

                    const existingProductIdsInWishlist = productsFromWishlist.map(product => product.id);
                    const existingProductIdsInCart = this.conomeService.productsFromCartSource.getValue().map(product => product.id);
                    
                    const newProductIds = productIds.filter(id => 
                        !existingProductIdsInWishlist.includes(id) && 
                        !existingProductIdsInCart.includes(id)
                    );
    
                    if (newProductIds.length === 0) {
                        return of(productsFromWishlist);
                    }
    
                    return this.conomeService.getProductsById(this.companyId, newProductIds)
                        .pipe(
                            map((newProducts: any) => {
                                const response = newProducts.products
                                return [...productsFromWishlist, ...response];
                            })
                        );
                }),
                switchMap(combinedProducts => {
                    return this.processCombinedProducts(combinedProducts);
                })
            )
            .subscribe({
                next: (products) => {
                    const currentProducts = this.conomeService.productsFromCartSource.getValue();
                    const uniqueProducts = this.removeDuplicatesById([...currentProducts, ...products]);
    
                    if (JSON.stringify(currentProducts) !== JSON.stringify(uniqueProducts)) {
                        this.conomeService.setproductsFromCart(uniqueProducts);
                    }
    
                },
                error: (err) => {
                    console.error('Error fetching cart data', err);
                }
            });
    }
    
    
    fetchConomeData() {
        this.conomeService.productsFromWishlist$.subscribe((products: any) => {
            this.productsFromConome = products;    
            if (this.productsFromConome.length > 0) {
                this.productsFromWishList = [];
                this.wishListCmsData.forEach((wishlistItem: any) => {
                    const product = this.productsFromConome.find(prod => prod.id === wishlistItem.productId);
                    if (product) {
                        const filteredProduct = {
                            ...product,
                            productDetailId: wishlistItem.productDetailId,
                            productDetails: product.productDetails.filter((detail: any) => detail.id === wishlistItem.productDetailId)
                        };
                        
                        this.productsFromWishList.push(filteredProduct);
                    }
                });
                
                const productsFromWishListIds = this.productsFromWishList.map((item: any) => item.id).filter((id: any) => id !== undefined);
                const productsJustForYouIds = this.productsFromWishList.map((item: any) => item.categoryId).filter((id: any) => id !== undefined);
                const isWishlistPage = this.router.url.includes('wishlist');
                if (isWishlistPage && this.conomeProductsJustForYou && this.conomeProductsJustForYou.length <=0) {
                    this.productsDataJustForYou(productsJustForYouIds).subscribe(() => {
                        let filteredProducts = this.conomeProductsJustForYou.filter((item: any) =>
                            !productsFromWishListIds.includes(item.id)
                        );
                        const getRandomSample = (array: any[], size: number) => {
                            const shuffled = array.sort(() => 0.5 - Math.random());
                            return shuffled.slice(0, size);
                        };
                        if (filteredProducts.length > 4) {
                            this.productsJustForYou = getRandomSample(filteredProducts, 4);
                        } else if (filteredProducts.length < 4) {
                            const additionalProducts = this.productsFromConome.filter(
                                (item: any) => !filteredProducts.some((fp: any) => fp.id === item.id) && !productsFromWishListIds.includes(item.id)
                            );
                            const additionalSample = getRandomSample(additionalProducts, 4 - filteredProducts.length);
                            this.productsJustForYou = [...filteredProducts, ...additionalSample];
                        } else {
                            this.productsJustForYou = filteredProducts;
                        }
                    });
                }
            }
        });

    }
    
    productsDataJustForYou(productsJustForYouIds: number[]): Observable<any> {
        return this.conomeService.getProductsByCategoryId(this.companyId, productsJustForYouIds, 1, 50).pipe(
            tap((data: any) => {
                this.conomeProductsJustForYou = data.products;
            })
        );
    }
    
    removeFromWishList(product: any, productDetailId: any) {

        if (this.userId === '') {
            let productsArray: any[] = [];
            try {
                const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
                if (storedWishlist) {
                    productsArray = JSON.parse(storedWishlist);
                    if (!Array.isArray(productsArray)) {
                        productsArray = [];
                    }
                }
            } catch (error) {
                console.error('Error parsing wishlist from localStorage:', error);
                productsArray = [];
            }
            const productIndex = productsArray.findIndex(item => 
                item.productId === product.id && item.productDetailId === productDetailId
            );
            if (productIndex > -1) {
                productsArray.splice(productIndex, 1); // Remove the product from the array
                this.cookieService.set(LOCAL_STORAGE.WISH_LIST, JSON.stringify(productsArray), { path: '/' });
                this.productsFromWishList = this.productsFromWishList.filter((item: any) =>
                    productsArray.some((prod: any) => 
                        prod.productId === item.id && prod.productDetailId === item.productDetailId
                    )
                );
                this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                this.showSuccessMessage = true;
                setTimeout(() => this.showSuccessMessage = false, 2000);
            } else {
                return;
            }
        } else {
            const cmsItem = this.wishListCmsData.find(
                (item: any) => item.productId === product.id && item.productDetailId === productDetailId && item.userId === this.userId
            );
    
            if (cmsItem) {
                this.cmsService.getWishListEntry(product.id, this.userId).subscribe({
                    next: (res: any) => {
                        const entry = res.data.find((entry: any) => entry.attributes.productDetailId === productDetailId); // Find specific productDetailId
                        if (entry) {
                            const entryId = entry.id;
                            this.cmsService.removeProductFromWishListById(entryId).subscribe({
                                next: () => {
                                    this.wishListCmsData = this.wishListCmsData.filter(
                                        (item: any) => !(item.productId === product.id && item.productDetailId === productDetailId)
                                    );
                                    this.productsFromWishList = this.productsFromWishList.filter((item: any) =>
                                        this.wishListCmsData.some((prod: any) =>
                                            prod.productId === item.id && prod.productDetailId === item.productDetailId
                                        )
                                    );
                                    this.getWishListData();
                                    this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                                    this.showSuccessMessage = true;
                                    setTimeout(() => this.showSuccessMessage = false, 2000);
                                }
                            });
                        } else {
                            return;
                        }
                    }
                });
            } else {
                return;
            }
        }
    }
    productModal(event:Event,productDetailModal: any, currentProduct: any) {
        event.stopPropagation();
        this.currentProduct = currentProduct
        this.currentProductImages = currentProduct.image
        this.currentProductDetail = currentProduct.productDetails[0];
        this.modalService.open(productDetailModal, { size: 'xl', centered: true });
        const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);

        if (availableDetail) {
            this.currentProductDetail = availableDetail;
        } else {
            return;
        }

    }

    isModalOpen(): boolean {
    return this.modalService.hasOpenModals();
    }

    selectProductDetail(detail: any) {
        if (detail.qt > 0) {
            this.currentProductDetail = detail;
        } else {
            const nextAvailableDetail = this.currentProduct.productDetails.find((d: any) => d.qt > 0);
            if (nextAvailableDetail) {
                this.currentProductDetail = nextAvailableDetail;
            } else {
                return;
            }
        }
    }

    addToCart(event:Event,product: any) {
        event.stopPropagation();
        this.currentProduct = product;
        if(!this.isModalOpen()){
            this.currentProductDetail = product.productDetails[0];
        }
        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
        this.showSuccessMessage = true;
        this.cartService.addToCart(product, this.currentProductDetail, this.userId) 
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);
    }

    shareOnFacebook(product: any) {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(shareUrl, '_blank');
    }

    shareOnWhatsApp(product: any) {

        const checkOutThisPost = this.translateService.instant('product-details.CHECK-OUT-THE-PRODUCT')
        const message = `${checkOutThisPost} ${this.currentLanguage == 'en' ? this.currentProduct.name_en : this.currentLanguage == 'sq' ? this.currentProduct.name_sq : this.currentProduct.name_mk}`;
        const url = encodeURIComponent(window.location.href);
        const whatsappLink = `https://api.whatsapp.com://send?text=${message} ${url}`;
        window.location.href = whatsappLink;
    }

    shareOnTwitter(product: any) {
        const postTitle = this.currentLanguage === 'en' ? this.currentProduct.name_en :
            this.currentLanguage === 'sq' ? this.currentProduct.name_sq :
                this.currentProduct.name_mk;

        const tweetText = encodeURIComponent(postTitle);
        const tweetUrl = encodeURIComponent(window.location.href);

        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;

        window.open(twitterShareUrl, '_blank', 'width=600,height=400');
    }

    async moveAllToBag() {
        this.showSuccessMessage = false;
        this.showWarningMessage = false;
        if (this.productsFromWishList && this.productsFromWishList.length > 0) {
            if (this.userId === '') {
                this.moveWishlistToLocalProducts();
                return;
            } else {
                try {
                    const existingCartResponse = await this.cmsService.getCartByUser(this.userId).toPromise();
                    const existingCart = existingCartResponse?.data || [];
                    const existingOrderMap = this.createOrderMap(existingCart);
                    const newCartItems = [];
                    const updateCartItems = [];
                    const updateCart: any[] = [];
                    const newProductIds = [];

                    for (let item of this.productsFromWishList) {
                        const productDetail = item.productDetails[0];
                        const newCartKey = `${item.id}_${productDetail.id}`;
    
                        if (existingOrderMap[newCartKey]) {
                            const existingItem = existingOrderMap[newCartKey];
                            const updatedQuantity = existingItem.quantity + 1;
                            updateCartItems.push({
                                id: existingItem.id,
                                quantity: updatedQuantity
                            });
                            updateCart.push({
                                id: existingItem.id,
                                productId: existingItem.productId,
                                productDetailId: existingItem.productDetailId,
                                quantity: updatedQuantity
                            });
    
                        } else if (productDetail.qt > 0) {
                            newCartItems.push({
                                productId: item.id,
                                productDetailId: productDetail.id,
                                quantity: 1,
                                userId: this.userId
                            });
                            newProductIds.push(item.id);
                        }
                    }

                    if (newCartItems.length > 0) {
                        await Promise.all(newCartItems.map(cartItem => this.cmsService.setCartList(cartItem).toPromise()));
                    }
    
                    if (updateCartItems.length > 0) {
                        await Promise.all(updateCartItems.map(cartItem =>
                            this.cmsService.updateCartProduct(cartItem).toPromise()
                        ));
                    }
    
                    const updatedCartItems = [
                        ...Object.values(existingOrderMap).map(item => {
                            const update = updateCart.find(u => u.productId === item.productId && u.productDetailId === item.productDetailId);
                            return update ? { ...item, quantity: update.quantity } : item;
                        }),
                        ...newCartItems.map(item => ({
                            productId: item.productId,
                            productDetailId: item.productDetailId,
                            quantity: item.quantity
                        }))
                    ];
                    this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
                    this.showSuccessMessage = true;
                    
                    const existingProductIds = existingCart.map((item: any) => item.attributes.productId);
                    const newIdsToFetch = newProductIds.filter(id => !existingProductIds.includes(id));
                    if (newIdsToFetch.length > 0) {
                        this.getCartData(newIdsToFetch); 
                    }
                    this.handleFetchedProducts(updatedCartItems);
    
                } catch (error) {
                }
            }
    
        } else {
            this.successMessage = this.translateService.instant("WISHLIST.EMPTY_WISHLIST");
            this.showWarningMessage = true;
        }
        setTimeout(() => {
            this.showSuccessMessage = false;
            this.showWarningMessage = false;
        }, 2000);
    }
 
    private createOrderMap(existingCart: any[]) {
        const orderMap: { [key: string]: any } = {};
        existingCart.forEach((item: any) => {
            const productId = item.attributes.productId;
            const productDetailId = item.attributes.productDetailId;
            const key = `${productId}_${productDetailId}`;
            orderMap[key] = {
                id: item.id,
                quantity: item.attributes.quantity,
                productId: productId,
                productDetailId: productDetailId
            };
        });
        
        return orderMap;
    }

    moveWishlistToLocalProducts() {
        let wishList = JSON.parse(this.cookieService.get(LOCAL_STORAGE.WISH_LIST) || '[]');
        let productsArray = JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || '[]');
    
        wishList.forEach((wishItem: any) => {
            const indexProduct = productsArray.findIndex((p: any) =>
                p.productId === wishItem.productId && p.productDetailId === wishItem.productDetailId
            );
    
            if (indexProduct !== -1) {
                productsArray[indexProduct].quantity += 1;
            } else {
                productsArray.push({
                    productId: wishItem.productId,
                    productDetailId: wishItem.productDetailId,
                    quantity: 1,
                    image: wishItem.image,
                });
            }
            this.handleFetchedProducts(productsArray)
        });
        this.cookieService.set(LOCAL_STORAGE.PRODUCTS, JSON.stringify(productsArray), { path: '/' });

        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART");
        this.showSuccessMessage = true;
    
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 2000);
    }
    
    handleFetchedProducts(productsArray: any) {
        this.conomeService.productsFromCart$.subscribe((fetchedProducts: any) => {
    
            const combinedProducts = [...fetchedProducts, ...this.productsFromConome];
    
            const uniqueProducts = this.removeDuplicatesById(combinedProducts);
    
            if (uniqueProducts.length > 0) {
                const updatedProductsArray = productsArray.map((cartItem: any) => {
                    const matchingProduct = uniqueProducts.find((p: any) => p.id === cartItem.productId);
                    return matchingProduct ? { ...cartItem, ...matchingProduct } : cartItem;
                });
    
                updatedProductsArray.sort((a: any, b: any) => {
                    if (a.id !== b.id) {
                        return a.id - b.id;
                    }
                    return a.productDetailId - b.productDetailId;
                });
    
                this.cartService.getNumberOfProducts(updatedProductsArray);
                this.cartService.updateCartProducts(updatedProductsArray);
            }
        });
    }
    
    private removeDuplicatesById(products: any[]): any[] {
        const seenIds = new Set();
        return products.filter(product => {
            if (seenIds.has(product.id)) {
                return false;
            }
            seenIds.add(product.id);
            return true;
        });
    }

    productDetails(id: any, name:string) {
        const formattedProductName = name.replace(/\s+/g, '-'); 
        this.router.navigate(['product-details', id, formattedProductName])
    }

    getRabate(item: any){
        if(item){
            return item.rebate
        }
    }
    
    truncateText(text: string, limit: number = 10): string {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) : text;
    }
}
