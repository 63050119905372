import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppModule } from 'src/app/app.module';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-details',
  // standalone: true,
  // imports: [AppModule],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent {
  @ViewChild('productDetailModal') productDetailModal: any;

  productsFromConome: any[] = [];
  environment: any;
  userId: string = '';
  productDetail: any;
  productId: number = -1
  currentProductDetail: any;
  currentLanguage = this.translateService.currentLang;
  breadCrumbItems!: any;
  serviceData: any[] = [];
  allImagesProductDetaill: any;
  value: number = 1; 
  currentRelatedProduct: any;
  currentRelatedProductDetail: any;
  showSuccessMessage: boolean = false;
  successMessage: string = '';
  showWarningMessage: boolean = false;
  wishListData: any[] = [];
  relatedItems: any;
  relatedHeader: any;
  companyId: number = -1;
  conomeRelatedItems: any[] = [];
  modalImage: string = '';
  selectedImage: string | null = null;

  constructor(
    private translateService: TranslateService,
    public cartService: CartService,
    private cookieService: CookieService,
    private conomeService: ConomeECommerceService,
    private cmsService: LozztoysCmsService,
    private userProfileService: UserProfileService,
    private sharedService: DataSharedService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.environment = environment
  }

  ngOnInit() {
    this.userProfileService.userId$.subscribe(userId => {
      this.userId = userId;
    });

    this.companyId = Number(this.cookieService.get('companyID'));

    this.activatedRoute.params.subscribe(params => {
      const productId = params['id'];
      if (productId) {
        this.productId = productId;
        this.getProduct()
      }
    });
    this.getDataFromCMS();
    this.getFromWishList();
    this.translateBreadcrumbItems();

    this.translateService.onLangChange.subscribe((res) => {
      if (this.currentLanguage !== res.lang) {
        this.currentLanguage = res.lang;
        this.updateUrlLanguage(res.lang,this.productDetail);
        this.translateBreadcrumbItems();
        this.getDataFromCMS();
      }
    });
  }

  updateUrlLanguage(newLang: string, productData: any) {
    const productNameField = `name${newLang.toUpperCase()}`;
    const translatedProductName = productData[productNameField as keyof typeof productData];
    const formattedProductName = translatedProductName.replace(/\s+/g, '-'); 

    this.location.replaceState(`/product-details/${productData.id}/${formattedProductName}`);
  }
  

  openImageModal(image: string) {
    this.modalImage = image;
    this.modalService.open(this.productDetailModal, { size: 'lg', centered: true });
  }

  getDataFromCMS() {
    const locale = this.currentLanguage;
    const populate = 'deep';
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;
    this.cmsService.getProductDetail(populateAndLocale).subscribe({
      next: (res) => {
        this.relatedHeader = res.data.attributes.relatedHeader
        this.serviceData = res.data.attributes.serviceSection;
      }
    })
  }

  private async translateBreadcrumbItems() {
    const homeLabel = await this.translateService.get("checkout.PRODUCT").toPromise();
    const firstLabel = await this.translateService.get("menu.PRODUCT_DETAILS").toPromise();

    this.breadCrumbItems = [
      { label: homeLabel, link: '/products' },
      { label: firstLabel, link: '/product-details' },
    ];
  }

  increaseValue() {
    if (this.value < this.currentProductDetail?.qt) {
      this.value++;
    }
  }

  decreaseValue() {
    if (this.value > 1) {
      this.value--;
    }
  }

  limitNumberLength(event: any, maxLength: number) {
    const input = event.target;      
    if (input.value > maxLength) input.value = maxLength
  }

  getProduct() {
    const selectedProductDetailID = this.sharedService.getProductDetailId();
    this.productsDetails().subscribe(response => {
      const res = response.products
      if (res.length > 0) {
        this.productDetail = res.find((prod: any) => prod.id == this.productId)
        
      if (selectedProductDetailID !== -1) {
        this.currentProductDetail = this.productDetail.productDetails.find((detail: any) => detail.id == selectedProductDetailID);
      } else {
        this.currentProductDetail = this.productDetail.productDetails[0];
      }

        this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages

        const productId = this.productDetail?.id;
        const productJustForYouCategoryId = this.productDetail?.categoryId;
        this.productsDataJustForYou(productJustForYouCategoryId).subscribe((res: any) => {
          const data = res.products;
          let filteredProducts = data.filter((item: any) =>
            item.id !== productId
          );

          const getRandomSample = (array: any[], size: number) => {
            const shuffled = array.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, size);
          };

          if (filteredProducts.length > 4) {
            this.relatedItems = getRandomSample(filteredProducts, 4);
          }
          else if (filteredProducts.length < 4) {
            const additionalProducts = data.filter(
              (item: any) => !filteredProducts.some((fp: any) => fp.id === item.id) && item.id !== productId
            );
            const additionalSample = getRandomSample(additionalProducts, 4 - filteredProducts.length);
            this.relatedItems = [...filteredProducts, ...additionalSample];
          }
          else {
            this.relatedItems = filteredProducts;
          }
        })
      }
    })
  }
    
  productsDataJustForYou(productsJustForYouIds: number[]): Observable<any> {
      return this.conomeService.getProductsByCategoryId(this.companyId, productsJustForYouIds, 1, 50).pipe(
          tap((data: any) => {
              this.conomeRelatedItems = data.products;
          })
      );
  }
  
  productsDetails(): Observable<any> {
  
    return this.conomeService.getProductsById(this.companyId, [Number(this.productId)]).pipe(
        tap((data: any) => {
            this.productDetail = data.products;
        })
    );
  }

  selectProductDetail(detail: any) {
    this.value = 1;
    if (detail.qt > 0) {
      this.currentProductDetail = detail;
      this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages


    } else {
      const nextAvailableDetail = this.productDetail.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentProductDetail = nextAvailableDetail;
        this.allImagesProductDetaill = this.currentProductDetail?.allProductDetailImages

      } else {
        return;
      }
    }
  }

  selectRelatedProduct(detail: any) {
    if (detail.qt > 0) {
      this.currentRelatedProductDetail = detail;
    } else {
      const nextAvailableDetail = this.currentRelatedProductDetail?.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentRelatedProductDetail = nextAvailableDetail;
      } else {
        return;
      }
    }
  }

  selectProductDetailForWishlist(detail: any) {
    if (detail.qt > 0) {
      this.currentProductDetail = detail;
    } else {
      const nextAvailableDetail = this.productDetail.productDetails.find((d: any) => d.qt > 0);
      if (nextAvailableDetail) {
        this.currentProductDetail = nextAvailableDetail;
      } else {
        return;
      }
    }
  }

  filterImg(e: any, image: any) {
    this.selectedImage = image;
    document.querySelectorAll('.product-gallery-thumblist a').forEach(element => {
      element.classList.remove('active')
    });
    const img: HTMLImageElement = (document.querySelector('#first img') as HTMLImageElement);
    img.src = image;
    e.target.closest('a').classList.add('active');
  }

  addToCart(product: any, currentProductDetail: any) {
    this.showSuccessMessage = true;
    this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-CART")
    this.cartService.addToCart(product, currentProductDetail, this.userId, this.value)
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 2000);

  }

  buyNow(product: any, productDetail: any) {
    const newCartItem = {
      ...product,
      productDetails: [productDetail],
      quantity: this.value,
    };
    this.cartService.setBuyNowProduct(newCartItem);
    this.router.navigate(['/checkout'])
  }
  
  addToWishList(product: any, currentProductDetail: any) {
    let productsArray: any[] = [];
    this.showSuccessMessage = false;
    this.showWarningMessage = false;
    const productDetailId = currentProductDetail.id;

    if (currentProductDetail.qt > 0) {      
        if (this.userId === '') {
            const storedWishlist = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
            if (storedWishlist) {
                productsArray = JSON.parse(storedWishlist);
                if (!Array.isArray(productsArray)) {
                    productsArray = [];
                }
            }

            const productIndex = productsArray.findIndex(
                item => item.productId === product.id && item.productDetailId === productDetailId
            );

            if (productIndex > -1) {
                productsArray.splice(productIndex, 1);
                this.cookieService.set(
                    LOCAL_STORAGE.WISH_LIST,
                    JSON.stringify(productsArray),
                    { path: '/' }
                );
                this.getFromWishList();
                this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                this.showSuccessMessage = true;
            } else {
                productsArray.push({ productId: product.id, productDetailId: productDetailId, image: product.image });
                this.cookieService.set(
                    LOCAL_STORAGE.WISH_LIST,
                    JSON.stringify(productsArray),
                    { path: '/' }
                );
                this.getFromWishList();
                this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                this.showSuccessMessage = true;
            }
        } else {
            const wishlistData = {
                productId: product.id,
                productDetailId: productDetailId,
                userId: this.userId,
                image: product.image
            };

            const productIndex = this.wishListData.findIndex(
                (item: any) => item.productId === product.id && item.productDetailId === productDetailId
            );

            if (productIndex > -1) {
                this.cmsService.getWishListEntry(product.id, this.userId).subscribe({
                    next: (res: any) => {
                        const entry = res.data.find((entry: any) => entry.attributes.productDetailId === productDetailId);
                        if (entry) {
                            const entryId = entry.id;
                            this.cmsService.removeProductFromWishListById(entryId).subscribe({
                                next: () => {
                                    this.wishListData = this.wishListData.filter(
                                        (item: any) => !(item.productId === product.id && item.productDetailId === productDetailId)
                                    );
                                    this.getFromWishList();
                                    this.successMessage = this.translateService.instant("home.PRODUCT-REMOVED-FROM-WISHLIST");
                                    this.showSuccessMessage = true;
                                }
                            });
                        }
                    }
                });
            } else {
                this.wishListData = [...this.wishListData, wishlistData];
                this.cmsService.setWishList(wishlistData).subscribe(
                    response => {
                        this.successMessage = this.translateService.instant("home.PRODUCT-ADDED-TO-WISHLIST");
                        this.showSuccessMessage = true;
                        this.getFromWishList();
                    }
                );
            }
        }
        setTimeout(() => {
            this.showSuccessMessage = false;
            this.showWarningMessage = false;
        }, 2000);
    }
}

  getFromWishList() {
    if (this.userId === '') {
        const wishListCmsData = this.cookieService.get(LOCAL_STORAGE.WISH_LIST);
        if(this.wishListData){
          this.wishListData = wishListCmsData ? JSON.parse(wishListCmsData) : []
          const wishListIds = this.wishListData.map((data: any) => data.productId)
          // this.getCartData(wishListIds)
        }
    } else {
        this.cmsService.getWishList(this.userId).subscribe(res => {
            this.wishListData = res.data
            .map((item: any) => {
                return {
                    productId: item.attributes.productId,
                    productDetailId: item.attributes.productDetailId,
                    userId: item.attributes.userId
                };
            });
        });
        if(this.wishListData){
          const wishListIds = this.wishListData.map((data: any) => data.productId)
          // this.getCartData(wishListIds)
        }
    }
}

  private processCombinedProducts(products: any[]): Observable<any> {
      const processedProducts = products.map(product => {
          return {
              ...product,
              processed: true
          };
      });

      return of(processedProducts);
  }

  getCartData(productIds: number[]): void {
      const companyId = Number(this.cookieService.get('companyID'));
      if (productIds.length === 0) {
          return;
      }
      this.conomeService.productsFromWishlist$
          .pipe(
              switchMap((productsFromWishlist: any[]) => {
                  const existingProductIds = productsFromWishlist.map(product => product.id);
                  const newProductIds = productIds.filter(id => !existingProductIds.includes(id));
                  if (newProductIds.length === 0) {
                      return of(productsFromWishlist);
                  }
                  return this.conomeService.getProductsById(companyId, newProductIds)
                      .pipe(
                          map((newProducts: any) => {
                            const response = newProducts.products
                            return [...productsFromWishlist, ...response];
                          })
                      );
              }),
              switchMap(combinedProducts => {
                  return this.processCombinedProducts(combinedProducts);
              })
          )
          .subscribe({
              next: (products) => {
                  this.conomeService.setproductsFromWishlist(products);
              },
              error: (err) => {
              }
          });
  }

  isInWishList(item: any): boolean {
    if (item) {
      return this.wishListData.some((wish: any) => wish.productId === item.id && wish.productDetailId === this.currentProductDetail?.id);
    } else {
      return false;
    }
  }

  isInWishListModal(item: any, itemDetails: any): boolean {
    if (item) {
      return this.wishListData.some((wish: any) => wish.productId === item.id && wish.productDetailId === itemDetails.id);
    } else {
      return false;
    }
  }


  isInWishListRelated(item: any): boolean {
    if (item) {
      return this.wishListData.some((wish: any) => wish.productId === item.id);
    } else {
      return false;
    }
  }

  productModal(productDetailModal: any, currentProduct: any) {
    this.currentRelatedProduct = currentProduct
    
    const availableDetail = currentProduct.productDetails.find((d: any) => d.qt > 0);
    if(currentProduct.productDetails[0].qt > 0){
      this.currentRelatedProductDetail = currentProduct.productDetails[0];
    } else if (availableDetail) {
      this.currentProductDetail = availableDetail;
      this.currentRelatedProductDetail = availableDetail
    } else {
      return;
    }
    this.modalService.open(productDetailModal, { size: 'xl', centered: true });
  }

  productDetails(id: any, name:string) {
    this.sharedService.setProductDetailId(-1);
    const formattedProductName = name.replace(/\s+/g, '-'); 
    this.router.navigate(['product-details', id, formattedProductName])
  }

  isOutOfStock(item: any): boolean {
    return item?.productDetails?.every((pd: any) => pd.qt <= 0);
  }

  truncateText(text: string, limit: number = 10): string {
    if (!text) return '';
    return text.length > limit ? text.substring(0, limit) : text;
  }

  redirectTo(link: string) {
    if(link){
      this.router.navigate([link])
    }
  }

  getRabate(item: any){
    if(item){
      return item.rebate
    }
  }
}
