<footer class="">
    <div class="d-flex flex-wrap footer-column footer">
        <div class="sm-content" *ngFor="let item of footerItems?.footerColumn; let i = index; let last = last">
            <div class="title">
                <ng-container *ngIf="i === 0; else elseBlock">
                    <h4>{{ item?.footerTitle }}</h4>
                </ng-container>
                <ng-template #elseBlock>
                    <h5>{{ item?.footerTitle }}</h5>
                </ng-template>

            </div>
            <div class="content text" *ngFor="let content of item?.textAndLink; let itemIndex = index">
                <ng-container *ngIf="i === 0 && itemIndex === 0; else elseContent">
                    <h5 class="pointer" (click)="handleClick(content.link)">{{ content.text }}</h5>
                </ng-container>
                <div class="lozztoys-input email-input" *ngIf="i === 0 && itemIndex === 1">
                    <input class="input" placeholder="Enter your email" #emailInput>
                    <span class="send-icon" (click)="sendTo()">
                        <img src="../../../assets/icons/paper-arrow-right.svg" alt="Send">
                    </span>
                </div>
                <ng-template #elseContent>
                    <div>
                        <p class="pointer mb-half" (click)="handleClick(content.link)">{{ content.text }}</p>
                    </div>
                </ng-template>


                <div *ngIf="last">
                    <div class="d-flex gap-15">
                        <div>
                            <img class="qr-code" src="{{cmsUrl + footerItems?.qrcode?.data.attributes?.url}}"
                                alt="{{footerItems?.qrcode?.data.attributes?.alternativeText}}">
                        </div>
                        <div class="download-icons">
                            <div *ngFor="let downloadApp of footerItems.downloadApp">
                                <img class="download-icon pointer"
                                    src="{{cmsUrl + downloadApp?.image?.data.attributes?.url}}"
                                    alt="{{downloadApp?.image?.data.attributes?.alternativeText}}">

                            </div>
                        </div>
                    </div>

                </div>
                <div class="media-icons" *ngIf="last">
                    <div *ngFor="let mediaIcons of footerItems.mediaIcons">
                        <img (click)="open(mediaIcons?.link)" target="_blank" class="media-icon pointer"
                            src="{{cmsUrl + mediaIcons?.image?.data.attributes?.url}}"
                            alt="{{mediaIcons?.image?.data.attributes?.alternativeText}}">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-divider"></div>

    <div class="all-rights">
        <div class="d-flex justify-content-center copyright">&copy; All rights reserved. Made by&nbsp;<a class="company-name"
                href="https://i-solve.info/" target="_blank">iSolve</a></div>
    </div>

</footer>