<!-- Header -->

<!--
  Helps to identify the screen size while resizing
  <label class="d-none d-xl-block">XL</label>
  <label class="d-none d-lg-block d-xl-none">LG</label>
  <label class="d-none d-md-block d-lg-none">MD</label>
  <label class="d-none d-sm-block d-md-none">SM</label>
-->

<app-banner title="{{'menu.PRODUCTS' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-banner>

<!-- Toolbar-->
<div class="mb-4 container">
  <div class="bg-light shadow-lg rounded-3">
    <div class="d-flex row">
      <!-- Search-->
      <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12">
        <div class="input-group pe-3 d-flex align-items-center">
            <input class="form-control search bg-transparent rounded-0 border-0 shadow-none ps-5 py-4" type="text"
                translate
                placeholder="{{currentLanguage === 'en' ? 'Search...' : currentLanguage === 'sq' ? 'Kërko...' : 'Пребарај...'}}"
                [(ngModel)]="searchText"
                (keyup.enter)="searchProducts()">
                <i class="ci-search position-absolute top-50 start-0 translate-middle-y zindex-5 ms-3 fs-lg text-muted"></i>
            <button class="button-outline icon-btn ms-2" type="button" (click)="searchProducts()">
                <i class="ci-search"></i>
            </button>
        </div>
    </div>    
      <!-- Sorting by date-->
      <div ngbDropdown class="flex-shrink-0 border-start col-xl-2 col-lg-2 col-md-3 col-sm-12 py-4"
        style="text-align: center;">
        <button ngbDropdownToggle class="btn border-0 p-0 fw-medium recently-added" type="button"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="d-sm-inline" style="text-align: center;">{{ 
          ((isRecentlyAdded ? 'products.RECENTLY-ADDED' : 'products.OLDEST')) | translate 
            }}</span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-accent w-100">
          <ul class="widget-list list-unstyled">
            <li class="d-flex justify-content-between align-items-center mb-1 pointer"
              [ngClass]="isRecentlyAdded? 'accent': ''">
              <div class="form-check dropdown-item dropdown-item-accent">
                <p class="form-check-label m-0" selected (click)="filterByRecentlyAdded()">{{"products.RECENTLY-ADDED" |
                  translate}}</p>
              </div>
            </li>
            <li class="d-flex justify-content-between align-items-center mb-1 pointer">
              <div class="form-check dropdown-item dropdown-item-accent" (click)="filterByOldest()"
                [ngClass]="isRecentlyAdded === false ? 'accent': ''">
                <p class="form-check-label m-0">{{"products.OLDEST" | translate}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Filters-->

<div class="d-flex flex-column justify-content-between pt-lg-0 container">
  <div class="d-flex flex-wrap">

    <!-- Categories-->
    <div ngbDropdown autoClose="outside" class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
      <button ngbDropdownToggle class="button-outline dropdown-toggle w-100" type="button" data-bs-toggle="dropdown"
        data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
        {{"questions.CATEGORY" | translate}}
      </button>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-accent dropdown-categories mt-lg-2 mt-1" >
        <div class="widget px-3">
          <!-- search categories -->
          <div class="position-relative">
            <input 
              type="text" 
              class="form-control mb-2" 
              placeholder="{{ 'questions.SEARCH' | translate }}" 
              [(ngModel)]="categoryFilter"
              (input)="filterCategories()"
            />
            <i class="fas fa-times pointer remove-icon position-absolute" 
               *ngIf="categoryFilter"
               (click)="removeFilterCategories()">
            </i>
          </div>
      
          <ul class="widget-list list-unstyled">
            @if(filteredCategories.length === categoriesFromConome.length){
              <li class="d-flex justify-content-between align-items-center mb-1 pointer"
                  (click)="loadAllProducts()"
                  [ngClass]="selectedCategory === -1 && selectedCategories.length === 0 ? 'accent' : ''">
                <div class="form-check dropdown-item dropdown-item-accent">
                  <input class="form-check-input" type="checkbox" 
                   [checked]="selectedCategory === -1 && selectedCategories.length === 0" 
                   />
                  <p class="form-check-label m-0">{{ 'questions.ALL' | translate }}</p>
                </div>
              </li>
            }
      
            <li class="d-flex justify-content-between align-items-center mb-1 pointer"
                *ngFor="let category of filteredCategories"
                (click)="multipleCategories(category)"
                [ngClass]="{ 'accent': isCategorySelected(category) || selectedCategory === category.id }">
              <div class="form-check dropdown-item dropdown-item-accent">
                <input class="form-check-input" type="checkbox" 
                [checked]="isCategorySelected(category)"
                />
                <p class="form-check-label m-0">{{ columnNameByLanguage(category) }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div ngbDropdown autoClose="outside" class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
      <button 
        ngbDropdownToggle 
        class="button-outline dropdown-toggle w-100" 
        type="button" 
        aria-haspopup="true" 
        aria-expanded="false">
        {{"products.BRAND" | translate}}
      </button>
      <!-- search brands -->
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-accent dropdown-gender mt-lg-2 mt-1">
        <div class="widget px-3">
          <div class="position-relative">
            <input 
              type="text" 
              class="form-control mb-2" 
              placeholder="{{ 'questions.SEARCH' | translate }}" 
              [(ngModel)]="brandFilter"
              (input)="filterBrands()"
            />
            <i class="fas fa-times pointer remove-icon position-absolute" 
               *ngIf="brandFilter"
               (click)="removeFilterBrands()">
            </i>
          </div>
          <!-- Brand List -->
          <ul class="widget-list list-unstyled">
            <li class="d-flex justify-content-between align-items-center mb-1 pointer"
                *ngFor="let brand of filteredBrands"
                [ngClass]="{'active': isBrandSelected(brand)}"
                (click)="toggleBrandSelection(brand)">
              <div class="form-check dropdown-item dropdown-item-accent">
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  [checked]="isBrandSelected(brand)" 
                />
                <p class="form-check-label m-0">{{ currentLanguage === 'sq' ? brand.nameSQ : brand.nameMK }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--Unecessary search button if needed uncomment the code snippet below-->
    <!-- <div class="mb-lg-3 mb-2 me-lg-3 me-2">
      <button class="button-outline icon-btn" type="button" (click)="searchProduct()">
        <i class="ci-search"></i>
      </button>
    </div> -->
    <div ngbDropdown autoClose="outside" class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
      <button 
        ngbDropdownToggle 
        class="button-outline dropdown-toggle w-100" 
        type="button" 
        aria-haspopup="true" 
        aria-expanded="false">
        {{"products.AGE" | translate}}
      </button>
      
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-accent dropdown-age mt-lg-2 mt-1">
        <div class="widget px-3">
          <!-- Age Range List -->
          <ul class="widget-list list-unstyled">
            <li class="d-flex justify-content-between align-items-center mb-1 pointer"
                *ngFor="let ageRange of ageRanges"
                [ngClass]="{'active': isAgeSelected(ageRange)}"
                (click)="toggleAgeSelection(ageRange)">
              <div class="form-check dropdown-item dropdown-item-accent">
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  [checked]="isAgeSelected(ageRange)" 
                />
                <p class="form-check-label m-0">{{ ageRange }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>    

    <div ngbDropdown autoClose="outside" class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
      <button 
        ngbDropdownToggle 
        class="button-outline dropdown-toggle w-100" 
        type="button" 
        aria-haspopup="true" 
        aria-expanded="false">
        {{"products.GENDER" | translate}}
      </button>
      
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-accent dropdown-gender mt-lg-2 mt-1">
        <div class="widget px-3">
          <!-- Gender Range List -->
          <ul class="widget-list list-unstyled">
            <li class="d-flex justify-content-between align-items-center mb-1 pointer"
                *ngFor="let gender of genderOptions"
                [ngClass]="{'active': isGenderSelected(gender.value)}"
                (click)="toggleGenderSelection(gender.value)">
              <div class="form-check dropdown-item dropdown-item-accent">
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  [checked]="isGenderSelected(gender.value)" 
                />
                <p class="form-check-label m-0">{{ gender.label }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Buttons for larger screens (sm, md, lg, and xl) -->
    <div class="d-none d-sm-flex mb-lg-3 mb-2 me-lg-3 me-2">
      <button 
        class="button-filter" 
        [ngClass]="{'button-filter-disabled': !hasFilters()}" 
        (click)="applyFilters()" 
        [disabled]="!hasFilters()">
        {{"products.APPLY_FILTERS" | translate}}
      </button>
    </div>

    <div class="d-none d-sm-flex d-flex align-items-start mb-lg-3 mb-2 ms-md-auto">
      <button 
        class="button-filter" 
        [ngClass]="{'button-filter-active': isFlashSales}" 
        (click)="toggleFlashSales()">
        {{"products.FLASH_SALES" | translate}}
      </button>
    </div>

    <!-- Buttons for smaller screens (only xs) -->
    <div class="d-flex flex-column d-sm-none w-100 mt-3 mb-3 mt-md-0">
      <button 
        class="button-filter w-100 mb-2" 
        [ngClass]="{'button-filter-disabled': !hasFilters()}" 
        (click)="applyFilters()" 
        [disabled]="!hasFilters()">
        {{"products.APPLY_FILTERS" | translate}}
      </button>

      <button 
        class="button-filter w-100" 
        [ngClass]="{'button-filter-active': isFlashSales}" 
        (click)="toggleFlashSales()">
        {{"products.FLASH_SALES" | translate}}
      </button>
    </div>
  </div>
  <div class="selected-categories-container">
    <div *ngIf="selectedCategories" class="selected-categories">
      <span *ngFor="let category of selectedCategories" class="badge bg-primary me-1 fs-6 mt-2 mr-2">
        <span *ngIf="category !== -1">
          {{ columnNameByLanguage(category) }}
          <span class="selected-categories-icon" (click)="removeCategory(category)" >
            <i class="ci-close"></i>
          </span>
        </span>
      </span>
    </div>
    <div class="button-container">
      <button class="clear-all" type="button" (click)="clearFilter()" *ngIf="selectedCategories.length > 0 || selectedBrands.length > 0 || selectedAges.length > 0 || selectedGender.length > 0 || searchText">
        <i class="ci-close"></i> {{ "products.CLEAR_ALL" | translate }}
      </button>
    </div>
  </div>
</div>
<section class="container tab-content py-sm-5 margin-2-top">
  <div class="row pt-3 pt-sm-0 px-1">
    <div *ngFor="let item of filteredProducts;let i = index;" class="col-lg-3 col-md-3 col-sm-6 mb-grid-gutter">
      <div class="card product-card pb-2" (click)="productDetails(item.id, item.productDetails[0].id, currentLanguage === 'sq' ? item.nameSQ : item.nameMK)">
        <div class="product-image-container">
          <p role="button" class="d-block" data-bs-toggle="modal">
            <img class="card-img-top"
              [src]="item.productDetails[0]?.allProductDetailImages[0] ? conomeUrl + '/' + item.productDetails[0]?.allProductDetailImages[0] : (item?.image ? conomeUrl + '/' + item?.image : '../assets/images/no-product.jpg')"
              alt="Image">
          </p>
          <div class="d-flex justify-content-between">
            @if(getRabate(item.productDetails[0]) > 0){
              <div class="discount absolute"><span class="discount-percentage">-{{getRabate(item.productDetails[0])}}%</span></div>
            }
            <div class="icons-container absolute">
              <div class="wishlist" (click)="item.productDetails.length > 1 ? productModal($event,wishListProductDetails, item) : (item.productDetails[0].qt > 0 ? addToWishList($event,item) : $event.stopPropagation())">
                <!-- <img [src]="isInWishList(item) ? '../../../assets/icons/is-favorite.svg' : '../../../assets/icons/favorite.svg'" alt="favorite"> -->
                <img *ngIf="isInWishList(item); else notFavorite" [src]="'../../../assets/icons/is-favorite.svg'" alt="favorite">
                <ng-template #notFavorite>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.6328 6.64738C21.3187 5.91997 20.8657 5.26079 20.2992 4.70675C19.7323 4.15107 19.064 3.70947 18.3305 3.40597C17.5699 3.09002 16.7541 2.9283 15.9305 2.93019C14.775 2.93019 13.6477 3.2466 12.668 3.84425C12.4336 3.98722 12.2109 4.14425 12 4.31535C11.7891 4.14425 11.5664 3.98722 11.332 3.84425C10.3523 3.2466 9.225 2.93019 8.06953 2.93019C7.2375 2.93019 6.43125 3.08957 5.66953 3.40597C4.93359 3.71066 4.27031 4.14894 3.70078 4.70675C3.13359 5.26017 2.6805 5.9195 2.36719 6.64738C2.04141 7.40441 1.875 8.20832 1.875 9.03566C1.875 9.81613 2.03438 10.6294 2.35078 11.4568C2.61563 12.1482 2.99531 12.8653 3.48047 13.5896C4.24922 14.7357 5.30625 15.931 6.61875 17.1427C8.79375 19.1513 10.9477 20.5388 11.0391 20.595L11.5945 20.9513C11.8406 21.1083 12.157 21.1083 12.4031 20.9513L12.9586 20.595C13.05 20.5364 15.2016 19.1513 17.3789 17.1427C18.6914 15.931 19.7484 14.7357 20.5172 13.5896C21.0023 12.8653 21.3844 12.1482 21.6469 11.4568C21.9633 10.6294 22.1227 9.81613 22.1227 9.03566C22.125 8.20832 21.9586 7.40441 21.6328 6.64738ZM12 19.0974C12 19.0974 3.65625 13.7513 3.65625 9.03566C3.65625 6.64738 5.63203 4.71144 8.06953 4.71144C9.78281 4.71144 11.2688 5.66769 12 7.06457C12.7312 5.66769 14.2172 4.71144 15.9305 4.71144C18.368 4.71144 20.3438 6.64738 20.3438 9.03566C20.3438 13.7513 12 19.0974 12 19.0974Z" fill="#9292A3"/>
                  </svg>
                </ng-template>
              </div>
              <div class="see-product" data-bs-toggle="modal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z" fill="#9292A3"/>
                  </svg>
                </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-2">
          <h3 class="product-title fs-md">
            <p data-bs-toggle="modal">
              {{ (currentLanguage === 'sq' ? item.nameSQ : item.nameMK) || 'products.NO-NAME' | translate }}
            </p>
          </h3>
          <div class="d-flex align-items-center justify-content-start">
            <div class="product-price-container">
              <div class="d-flex gap-10px">
                <span class="product-price">{{ item.productDetails[0]?.sellingPriceWithRebate }} {{"currency.CURRENCY_VALUE"
                  | translate }}</span>
                @if(getRabate(item.productDetails[0]) > 0){
                  <span class="product-price-rabate">
                    {{item.productDetails[0]?.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="add-to-cart" [class.disabled]="item.productDetails.length === 1 && item.productDetails[0].qt <= 0"
          (click)="item.productDetails.length > 1 ? productModal($event,hasProductDetails, item) : (item.productDetails[0].qt > 0 ? addToCart($event,item) : $event.stopPropagation())">
          {{'products.ADD-TO-CART'|translate}}</div>
      </div>
    </div>
  </div>


<!-- Load more button -->
<nav class="d-md-flex justify-content-between align-items-center text-center text-md-start pt-2 pb-4 mb-md-2"
  aria-label="Page navigation">
  <div class="d-md-flex align-items-center w-100">
    <span class="fs-sm text-muted me-md-3">
      {{"menu.SHOWING" | translate}} {{filteredProducts.length}} {{"paginator.OF" | translate}} {{totalFilteredProductsCount}} {{"menu.PRODUCTS" | translate | lowercase}}
    </span>    
    <ngb-progressbar type="dark" [value]="barFilled" class="progress w-100 my-3 mx-auto mx-md-0" style="max-width: 10rem; height: 4px;"></ngb-progressbar>
  </div>
  <button *ngIf="showMoreButton" (click)="showMore()" class="btn btn-outline-primary more-btn" type="button">
    {{"menu.LOAD-MORE-PRODUCTS" | translate}}
  </button>
</nav>
</section>

<ng-template #wishListProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute" type="button" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
    <div class="px-lg-3">
      <div class="has-product-details">
        <div class="pb-3 mt-2">
          <div class="mb-3 d-flex gap-10px">
            <span class="h3 fw-normal text-accent me-1">
              {{currentProductDetail.sellingPriceWithRebate | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
            </span>
            @if(getRabate(currentProductDetail) > 0){
              <span class="h3 fw-normal product-price-rabate">
                {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            }
          </div>
          <div class="mb-3 d-flex align-items-center">
            <button [class.disabled]="currentProductDetail.qt <= 0" (click)="addToWishList($event,currentProduct)"
              class="btn btn-primary btn-shadow d-block w-100">
              @if(isInWishListModal(currentProduct, currentProductDetail)){
                <div>
                  {{'WISHLIST.REMOVE_FROM_WISHLIST'|translate}}
                </div>
              } @else {
                <div>
                  {{'WISHLIST.ADD_TO_WISHLIST'|translate}}
                </div>
              }
            </button>
          </div>
        </div>
        <div class="mt-2">
          <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
          <div class="product-details-names pointer">
            <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails"
              (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0"
              [class.selected]="detail === currentProductDetail">
              {{ detail.productName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="success-message message-container" *ngIf="showSuccessMessage">
    <div class="success-message">
      {{ successMessage }}
    </div>
  </div>
</ng-template>

<ng-template #hasProductDetails let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute" type="button" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
    <div class="px-lg-3">
      <div class="has-product-details">
        <div class="pb-3 mt-2">
          <div class="mb-3">
            <span class="h3 fw-normal text-accent me-1">
              {{currentProductDetail.sellingPriceWithRebate | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
            </span>
            @if(getRabate(currentProductDetail) > 0){
              <span class="h3 fw-normal product-price-rabate">
                {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
            }
          </div>
          <div class="mb-3 d-flex align-items-center">
            <button [class.disabled]="currentProductDetail.qt <= 0" (click)="addToCart($event,currentProduct)"
              class="btn btn-primary btn-shadow d-block w-100">
              <i class="ci-cart fs-lg me-2"></i>
              {{'products.ADD-TO-CART'|translate}}
            </button>
          </div>
        </div>
        <div class="mt-2">
          <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
          <div class="product-details-names pointer">
            <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails"
              (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0"
              [class.selected]="detail === currentProductDetail">
              {{ detail.productName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="success-message message-container" *ngIf="showSuccessMessage">
    <div class="success-message">
      {{ successMessage }}
    </div>
  </div>
</ng-template>

<ng-template #productDetailModal let-modal>
  <div class="bg-light shadow-lg rounded-3 px-4 py-3 position-relative">
    <button class="close-button btn-close position-absolute" type="button" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
    </button>
    <div class="px-lg-3">
      <div class="row">
        <div class="col-lg-7 pe-lg-0 pt-lg-4">
          <div class="product-gallery">
            <div class="product-gallery-preview order-sm-2">
              <div class="active" id="first">
                <img class="image-zoom"
                  src="{{ currentProductImages && environment.conomeUrl  + '/' + currentProductImages}}"
                  alt="Product image">
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-5 pt-4 pt-lg-0">
          <div class="product-details ms-auto pb-3 mt-2">
            <div class="mb-3">
              <span class="h3 fw-normal text-accent me-1">
                {{currentProductDetail.sellingPriceWithRebate | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
              </span>
              @if(currentProductDetail.rabate > 0){
                <span class="h3 fw-normal product-price-rabate">
                  {{currentProductDetail.sellingPriceWithVat | number: '0.2' }} {{'currency.CURRENCY_VALUE' | translate}}
                </span>
              }
            </div>
            <div class="mb-3 d-flex align-items-center">
              <button [class.disabled]="currentProductDetail.qt <= 0" (click)="addToCart($event,currentProduct)"
                class="btn btn-primary btn-shadow d-block w-100">
                <i class="ci-cart fs-lg me-2"></i>
                {{'products.ADD-TO-CART'|translate}}
              </button>
            </div>
            <h4>{{(currentLanguage === 'sq' ? currentProduct.nameSQ : currentProduct.nameMK) || 'products.NO-NAME' |
              translate }}</h4>
            <div ngbAccordion>
              <div ngbAccordionItem [collapsed]="false">
                <h4 ngbAccordionHeader>
                  <button class="border-0 bg-transparent" ngbAccordionButton>
                    <i
                      class="ci-announcement text-muted fs-lg align-middle mt-n1 me-2"></i>{{'product-details.PRODUCT-INFO'
                    | translate}}
                  </button>
                </h4>

                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <!-- <h6 class="fs-sm mb-2">{{'product-details.DESCRIPTION' | translate}}</h6> -->
                      <div class="scrollable-description" markdown ngPreserveWhitespaces>
                        {{currentLanguage === 'en' ? currentProduct.notes : currentLanguage === 'sq' ?
                        currentProduct.notes : currentProduct.notes}}

                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4" *ngIf="currentProduct.productDetails.length>1">
            <h5>{{'product-details.PRODUCT-SIZE'|translate}}</h5>
            <div class="product-details-names pointer">
              <div class="product-details-name" *ngFor="let detail of currentProduct.productDetails"
                (click)="selectProductDetail(detail)" [class.disabled]="detail.qt <= 0"
                [class.selected]="detail === currentProductDetail">
                {{ detail.productName }}
              </div>
            </div>
          </div>
          <div class="my-4" *ngIf="currentProduct.noProduct">
            <div class="product-number">{{'product-details.PRODUCT-NUMBER'|translate}}: {{currentProduct.noProduct}}
            </div>
          </div>
          <div class="share">
            <span>{{'post-details.SHARE-ON' | translate}}</span>
            <i class="ci-facebook share-button" (click)="shareOnFacebook(currentProduct)"></i>
            <i class="ci-twitter share-button" (click)="shareOnTwitter(currentProduct)"></i>
            <i class="ci-whatsapp share-button" (click)="shareOnWhatsApp(currentProduct)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="success-message message-container" *ngIf="showSuccessMessage">
    <div class="success-message">
      {{ successMessage }}
    </div>
  </div>
</ng-template>

<div class="success-message message-container" *ngIf="showSuccessMessage">
  <div class="success-message">
    {{ successMessage }}
  </div>
</div>
<div class="warning-message message-container" *ngIf="showWarningMessage">
  <div class="warning-message">
    {{ successMessage }}
  </div>
</div>
<!-- <app-footer></app-footer> -->