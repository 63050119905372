import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './pages/products/products.component';
import { CheckoutComponent } from './pages/checkout/checkout.component'; 
import { AuthGuardService } from './services/login/auth-guard.service';
import { CartComponent } from './pages/cart/cart.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact/contact.component';
import { PaymentComponent } from './services/payment-service/payment-service.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    { path: 'cart', component: CartComponent },
    { path: 'checkout', component: CheckoutComponent },
    { path: 'signup', component: SignupComponent},
    { path: 'login', component: LoginComponent},
    { path: 'login/verification-failed', component: LoginComponent},
    { path: 'login/verification-successful', component: LoginComponent},
    { path: 'about-us', component: AboutusComponent},
    { path: 'userprofile', canActivate :[AuthGuardService], loadChildren: () => import('./user-profile-routing.module').then((m) => m.UserProfileRoutingModule)},
    
    { path: 'home', component: HomeComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'contact', component: ContactComponent},
    { path: 'card-payment', component: PaymentComponent},
    { path: 'wishlist', component: WishlistComponent },
    { path: 'product-details/:id/:name', component: ProductDetailsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
