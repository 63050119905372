<app-banner title="{{ 'menu.ABOUT-US' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>

<div class="container">
    <div class="contentLeft">
      <div class="row img-container">
        @for (item of aboutUs?.images?.data; track $index) {
          <div class="imgWrapper">
            <img [src]="cmsUrl + item.attributes.url" alt="image">
         </div>
        }
      </div>
    </div>
    <div class="contentRight">
      <div class="content">
        <h4>{{aboutUs.sectionTitle}}</h4>
        <h2>{{aboutUs.sectionSubtitle}}</h2>
        @if(isLongText){
        <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'" class="text-container">
        <p>{{ isExpanded ? aboutUs.paragraph : truncatedText }}</p>
        </div>
      } @else {
        <p>{{ aboutUs.paragraph }}</p>
      }
      @if(isLongText){
        <button class="read-more-button" (click)="toggleReadMore()"> {{ isExpanded ? aboutUs.readLessButton : aboutUs.readMoreButton }}</button>
      }
      </div>
    </div>
  </div>